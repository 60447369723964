import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import './BigScreenStyle.css'

const ChassisTable = (props: any) => {
    const {chassisData, rowName} = props;
    return (
        <TableContainer component={Paper} elevation={0} style={{marginTop: '40px'}}>
            <Table size="small">
                <TableBody>
                    <TableRow className='bs-row'>
                        <TableCell className='bs-cell fw-bold' style={{width: `${rowName === 'Chassis to be Delivered' ? '500px' : '200px'}`, fontSize: '1.45vmin', backgroundColor: "#d9f4a3"}}>
                            {rowName}
                        </TableCell>
                        {
                            chassisData[rowName].map((row: any, i: number) => (
                                <TableCell className='bs-cell' style={{width: '100px', fontSize: '1.4vmin'}}>
                                    {row.chassisNo}
                                </TableCell>
                            ))
                        }
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ChassisTable;