import React, { useState } from "react";

export default function Timer() {
    const [currentTime, setCurrentTime] = useState(new Date());
    React.useEffect(() => {
        const intervalId = setInterval(() => {
          setCurrentTime(new Date());
        }, 1000);
        return () => clearInterval(intervalId);
      }, []);
    return (
        <div className="d-flex gap-2 px-2 date">
        <p className="m-0 font-lg fw-medium">Date: </p>
        <div className="d-flex gap-4">
          <p className="m-0 font-lg fw-medium">
            {currentTime.toLocaleDateString("en-GB")}
          </p>
          <p className="m-0 font-lg fw-medium">
            {currentTime.toLocaleTimeString()}
          </p>
        </div>
      </div>
    );
}