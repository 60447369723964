import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';

const PrepDataTable = (props: any) => {
    const {rowName, prepData, line} = props;

    const getBackgroundColor = (stationAlert: any) => {
      const colorMap: Record<string, string> = {
        Alert: "#FF0000",
        "Work Active": "#FFA500",
        "Work Complete": "#008000",
        Stores: "#0000FF",
        Maintenance: "#808080",
      };
      return colorMap[stationAlert] || "inherit";
    };

    return (
        <TableContainer component={Paper} elevation={3} style={{marginTop: '10px'}}>
            <Table size='small'>
                <TableHead>
                    <TableRow className='bs-prep-table--row' style={{width: '100%', backgroundColor: "rgb(255 253 249)"}}>
                        {line === "main" && 
                            <TableCell colSpan={3} className='text-center fw-bold py-2' style={{fontSize: '1.6vmin', width: '100%'}}>
                                {rowName}
                            </TableCell>
                        }
                    </TableRow>
                    <TableRow className='bs-prep-table--row' style={{width: '100%', backgroundColor: "#f0f8ff"}}>
                        <TableCell colSpan={3} className='text-center' style={{fontSize: '1.5vmin', width: '100%'}}>
                            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                <span className='text-uppercase'>{line} Line</span>
                                <span>Complete Jobs: {prepData[rowName][0][line].filter((row: any) => row.stationAlert === "Work Complete").length} of {prepData[rowName][0][line].length}</span>
                            </div>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{fontSize: '1.3vmin'}}>
                            Sequence
                        </TableCell>
                        <TableCell style={{fontSize: '1.3vmin'}}>
                            Chassis #
                        </TableCell>
                        <TableCell style={{fontSize: '1.3vmin'}}>
                            Status
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        prepData[rowName][0][line].map((row:any, i: number) => (
                            <TableRow key={i}>
                                <TableCell style={{fontSize: '1.2vmin'}}>
                                    {row.priorityNo}
                                </TableCell>
                                <TableCell style={{fontSize: '1.2vmin'}}>
                                    {row.chassisNo}
                                </TableCell>
                                <TableCell style={{fontSize: '1.2vmin',
                                    backgroundColor: getBackgroundColor(row.stationAlert),
                                }}>
                                    {/* {row.stationAlert} */}
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PrepDataTable;