import Modal from "react-bootstrap/Modal";
import CustomDropDown from "./CustomDropDown";
import React from "react";
const dropDownValues = [
  { value: "Draft", label: "Draft" },
  { value: "Cancelled", label: "Cancelled" },
  { value: "Submitted", label: "Submitted" },
  { value: "Confirmed Pending", label: "Confirmed Pending" },
  { value: "Rejected", label: "Rejected" },
  { value: "Accepted", label: "Accepted" },
  { value: "Final", label: "Final" },
  { value: "AwaitingDealerSignOff", label: "AwaitingDealerSignOff" },
  { value: "DealerSignedOff", label: "DealerSignedOff" },
  { value: "Planned", label: "Planned" },
  { value: "Customer", label: "Customer" },
  { value: "Scheduled", label: "Scheduled" },
  { value: "Production", label: "Production" },
  { value: "Completed", label: "Completed" },
  { value: "Delivered", label: "Delivered" },
];
const checkboxOptions = [
  { id: "1", label: "Chassis Ordered" },
  { id: "2", label: "Body Panels Ordered" },
  { id: "3", label: "Benchtop Ordered" },
  { id: "4", label: "Splashback Ordered" },
  { id: "5", label: "Upholstery Ordered" },
];
function ScheduleModal(props: any) {
  console.log("ScheduleModal  props", props);
  const {
    rowdata,
    rowindex,
    checkboxids,
    handlechangecheckbox,
    handlesavemodal,
    tabledata,
  } = props;
  const [inputField, setInputField] = React.useState({
    keyIndex: "",
    rowindex: "",
    isInputField: false,
  });
  const [editedVal, setEditedVal] = React.useState("");
  const [selectedDropDown, setSelectedDropDown] = React.useState({
    keyIndex: "",
    rowindex: "",
    value: "",
  });

  console.log("tabledata", tabledata[rowindex]);
  const handleInputField = (keyIndex: any, rowindex: any) => {
    setInputField({
      keyIndex,
      rowindex: rowindex + 1,
      isInputField: true,
    });
  };
  const handleInputData = (keyIndex: any, rowindex: any, event: any) => {
    setEditedVal(event.target.value);
    const keyName = rowdata[0].cells[keyIndex].text;
    console.log("keyName", keyName, keyIndex);
    rowdata[rowindex + 1].cells[keyIndex].text = event.target.value;

    //Update the Main data
    if (Number(keyIndex) === 11)
      tabledata[Number(rowindex)].Notes = event.target.value;
    else if (Number(keyIndex) === 12)
      tabledata[Number(rowindex)].ChassisNotes = event.target.value;
    else if (Number(keyIndex) === 13)
      tabledata[Number(rowindex)].ElectricalSystem = event.target.value;
    else if (Number(keyIndex) === 14)
      tabledata[Number(rowindex)].UpgradePack = event.target.value;
    else if (Number(keyIndex) === 15)
      tabledata[Number(rowindex)].ProductionValue = event.target.value;
    else if (Number(keyIndex) === 16)
      tabledata[Number(rowindex)].DrawnBy = event.target.value;
  };
  const handleDropDownChange = (keyIndex: any, rowindex: any, event: any) => {
    setSelectedDropDown({
      keyIndex,
      rowindex: rowindex + 1,
      value: event.target.value,
    });
    rowdata[rowindex + 1].cells[keyIndex].text = event.target.value;
    tabledata[Number(rowindex)].Status = event.target.value;
  };

  return (
    <Modal
      style={{ zIndex: "500000" }}
      {...{ show: props.show, onHide: props.onHide }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="row gx-0 gx-lg-2">
          <div className="col-6">
            {Object.keys(rowdata[0].cells)
              .slice(2)
              .slice(0, 8)
              .map((key, index) => {
                return (
                  <div className="row gx-3" key={index}>
                    <div className="col-6">
                      <p className="font-sm text-black fw-medium">
                        {rowdata[0].cells[key].text}
                      </p>
                    </div>
                    <div className="col-6">
                      {rowdata[0].cells[key].text === "Status" ? (
                        <select
                          style={{ width: "90%" }}
                          className="font-sm text-black"
                          onChange={(event) =>
                            handleDropDownChange(key, rowindex, event)
                          }
                        >
                          <option
                            className="pb-2"
                            value={rowdata[rowindex + 1].cells[key].text}
                          >
                            {rowdata[rowindex + 1].cells[key].text
                              ? rowdata[rowindex + 1].cells[key].text
                              : "----select----"}
                          </option>
                          {dropDownValues.map((val, i) => (
                            <option className="pb-2" value={val.value} key={i}>
                              {val.label}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <p className="font-sm text-black">
                          {rowdata[rowindex + 1].cells[key].text}
                        </p>
                      )}
                    </div>
                    <div className="col-12 mt-1">
                      <div className="row gy-3">
                        {rowdata[0].cells[key].text === "Status" &&
                          rowdata[rowindex + 1].cells[key].text ===
                            "Scheduled" &&
                          checkboxOptions.map((option, i) => (
                            <div className="col-6" key={i}>
                              <div
                                className="font-sm text-black d-flex align-items-center gap-2"
                                key={option.id}
                              >
                                <input
                                  type="checkbox"
                                  id={`checkbox-${option.id}`}
                                  name={`checkbox-${option.id}`}
                                  value={option.label}
                                  onChange={(event) =>
                                    handlechangecheckbox(event)
                                  }
                                  checked={checkboxids.includes(option.id)}
                                />
                                <label htmlFor={`checkbox-${option.id}`}>
                                  {option.label}
                                </label>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="col-6">
            {Object.keys(rowdata[0].cells)
              .slice(9)
              .map((key, index) => {
                return (
                  <div className="row gx-3" key={index}>
                    <div className="col-6">
                      <p className="font-sm text-black fw-medium">
                        {rowdata[0].cells[key].text}
                      </p>
                    </div>
                    <div
                      className="col-6"
                      onClick={() => handleInputField(key, rowindex + 1)}
                    >
                      {(rowdata[0].cells[key].text === "Notes" ||
                        rowdata[0].cells[key].text === "Chassis Notes" ||
                        rowdata[0].cells[key].text === "Electrical System" ||
                        rowdata[0].cells[key].text === "Upgrade Pack" ||
                        rowdata[0].cells[key].text === "Prod Value" ||
                        rowdata[0].cells[key].text === "Drawn By") &&
                      inputField.keyIndex === `${key}` &&
                      inputField.isInputField ? (
                        <input
                          className="font-sm text-black"
                          style={{ width: "100%" }}
                          type="text"
                          value={rowdata[rowindex + 1]?.cells[key]?.text}
                          onChange={(event) =>
                            handleInputData(key, rowindex, event)
                          }
                        />
                      ) : (
                        <p className="font-sm text-black">
                          {rowdata[rowindex + 1].cells[key].text}
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="" onClick={props.onHide}>
          Close
        </button>
        <button
          className="btn blue-btn"
          onClick={() => handlesavemodal(rowindex, checkboxids, props.onHide)}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}
export default ScheduleModal;
