import _ from "lodash";
import {
  IInStockAndDeliveredUpdateModel,
  IUpdateProcessDataModel,
} from "../../../generated/generated-proxies";
import server, { REACT_APP_API_BASE_URL } from "../../../generated/proxies";
// import httpClient from "../../https/httpClient";

export default class DailyReviewService {
  axiosPrivate: any;
  constructor(axiosPrivate: any) {
    this.axiosPrivate = axiosPrivate;
  }
  // --------------- Assembly ----------------
  async fetchProductionReviewData(lineValue: string) {
    const line = _.first(lineValue.split("-"));
    const response = await this.axiosPrivate.get(
      `get_production_lines_by_line/${line}`
    );
    return response.data;
  }

  async fetchSimulatedData(lineValue: string) {
    const response =
      await server.simulatorJsonData.getAllSimulatorJsonDataSimulatorJsonDataLineViewGet(
        lineValue,
        {
          baseURL: REACT_APP_API_BASE_URL,
        }
      );
    return response.data;
  }

  async fetchCompletedItems(lineValue: string) {
    const line = _.first(lineValue.split("-"));
    const response = await this.axiosPrivate.get(
      `get_all_completed_chassis_by_line/${line}`
    );
    return response.data;
  }

  async updateSimulatorJsonData(
    lineVal: string,
    rowName: string,
    data: IInStockAndDeliveredUpdateModel
  ) {
    const response =
      await server.updateSimulatorJsonData.updateSimulatorJsonDataUpdateSimulatorJsonDataLineViewRowNamePut(
        lineVal,
        rowName,
        data,
        { baseURL: REACT_APP_API_BASE_URL }
      );
    return response.data;
  }

  // async updateProcessedData(reqBody: any) {
  //   const response =
  //     await server.updateProcessedData.updateProcessDataUpdateProcessedDataLineViewPost(
  //       lineVal,
  //       reqBody,
  //       { baseURL: REACT_APP_API_BASE_URL }
  //     );
  //   return response.data;
  // }

  async updateProcessedData(id: any, reqBody: any) {
    const response = await this.axiosPrivate.put(
      `update_production_line/${id}`,
      reqBody
    );
    return response.data;
  }

  //------------------ Preparation --------------

  async fetchPdfUrlToBlob(pdfUrl: string) {
    try {
      const response = await fetch(pdfUrl);
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error("Could not download pdf");
    }
  }
}
