import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { CircleLoader } from "react-spinners";
import userPresenter from "./userPresenter";
import { useSelector } from "react-redux";
import { IUserInfo } from "../store/actions";

const Login = () => {
  const authInfo: IUserInfo = useSelector(
    (state: any) => state.userAuthInfoState
  );
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const login = async () => {
    setIsLoading(true);
    try {
      const redirectUrl = await userPresenter.login();
      if (redirectUrl) navigate(redirectUrl);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };



  return (
    <>
      {
      (
        <div className="login-layout d-flex flex-column align-items-center justify-content-center position-relative">
          <div
            className={
              isLoading
                ? "disabled d-flex flex-column justify-content-center align-items-center"
                : "d-flex flex-column justify-content-center align-items-center"
            }
          >
            <div className="d-flex flex-column justify-content-center align-items-center gap-3">
              <div className="login__logo">
                <img src="./crusader-logo.svg" alt="" />
              </div>
              <h2 className="mb-3 text-center">Production Schedule System</h2>
              <button
                className="d-none d-md-block btn blue-btn font-lg"
                onClick={login}
              >
                Login
              </button>
            </div>
            <CircleLoader
              loading={isLoading}
              color="#36d7b7"
              cssOverride={{
                display: "block",
                margin: "0 auto",
                borderColor: "#36d7b7",
              }}
              size={50}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
          <div
            className="d-flex d-md-none flex-column justify-content-end position-absolute"
            style={{ width: "90%", bottom: "0" }}
          >
            <p className="text-center font-md" style={{ color: "#A9A9A9" }}>
              A system To manage caravan production line schedules
            </p>
            <button className="btn blue-btn font-lg" onClick={login}>
              Login
            </button>
          </div>
        </div>
      )
      }
    </>
  );
};

export default Login;
