import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function ConfirmationModal(props:any){
    const {show, onClose, onConfirm, title, body} = props;

    return (
        <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onClose}>
            Cancel
            </Button>
            <Button variant="danger" onClick={onConfirm}>
            Confirm
            </Button>
        </Modal.Footer>
        </Modal>
    );
};