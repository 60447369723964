import React from 'react';

const ChassisDeliveredTable = (props: any) => {
    const {chassisData, rowName} = props;
    return (
        <div className="chassis-data d-flex flex-wrap gap-0">
            <div className="chassis-data-label d-flex justify-content-center align-items-center">
                <p className='mb-0'>{rowName}</p>
            </div>
            <div className="chassis-data-row d-flex flex-wrap gap-0">
            {
                chassisData[rowName].map((row: any, i: number) => (
                    <div className="chassis-number d-flex justify-content-center align-items-center">
                        <p className='mb-0'>{row.chassisNo}</p>
                    </div>
                ))
            }
            </div>
        </div>
    );
};

export default ChassisDeliveredTable;