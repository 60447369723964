import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const JobItemDetailsModal = (props: any) => {
    const {jobListItem} = props;
    return (
        <Modal
      {...{show: props.show, onHide: props.onHide}}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {jobListItem.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Body of Modal
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
    );
};

export default JobItemDetailsModal;