/* eslint-disable jsx-a11y/anchor-is-valid */
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { IUserInfo } from "../store/actions";
import "./Layout.css";
import userPresenter from "../authentications/userPresenter";
import Login from "../authentications/Login";

const Layout = () => {
  const currentUserInfo: IUserInfo = useSelector((state: any) => {
    return state.userAuthInfoState;
  });

  const navigate = useNavigate();

  const logout = () => {
    userPresenter.logOut();
    navigate("/home");
  };

  return (
    <main className="App">
      {currentUserInfo?.roles ? (
        <nav className="navbar nav fixed-top navbar-expand-xl">
          <div className="container-fluid">
            <div className="d-flex flex-row-reverse flex-lg-row align-items-center gap-2">
              <h1 className="m-0 d-flex justify-content-center align-items-center">
                <div className="logo navbar-brand p-0 m-0 d-flex justify-content-center align-items-center">
                  <img src="./Crusader_Shield.png" alt="Logo" />
                </div>
              </h1>
            </div>
            <div className="d-flex align-items-center gap-4">
              <button
                className="navbar-toggler border-0 text-black p-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            {currentUserInfo.accessToken && currentUserInfo.roles && (
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto align-items-start align-items-xl-center mt-2 mt-xl-0">
                  {currentUserInfo.roles.includes("user.admin") ? (
                    <>
                      <li className="nav-item">
                        <NavLink to="/schedule" className="nav-link">
                          Schedule
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/daily-review" className="nav-link">
                          Production Review
                        </NavLink>
                      </li>
                      {/* <li className="nav-item">
                        <NavLink to="/form" className="nav-link">
                          Config
                        </NavLink>
                      </li> */}

                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Configurations
                        </a>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <li>
                            <NavLink to="/form" className="dropdown-item">
                              Schedule
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/production-review-setting"
                              className="dropdown-item"
                            >
                              Production Review
                            </NavLink>
                          </li>
                          {/* <li>
                            <NavLink to="/option3" className="dropdown-item">
                              Option 3
                            </NavLink>
                          </li> */}
                        </ul>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/pick-list" className="nav-link">
                          Pick List
                        </NavLink>
                      </li>
                    </>
                  ) : currentUserInfo.roles.includes("production-manager") ? (
                    <>
                      <li className="nav-item">
                        <NavLink to="/daily-review" className="nav-link">
                          Production Review
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/production-review-setting"
                          className="nav-link"
                        >
                          Configuration
                        </NavLink>
                      </li>
                    </>
                  ) : currentUserInfo.roles.includes("operation-manager") ? (
                    <>
                      <li className="nav-item">
                        <NavLink to="/production-review" className="nav-link">
                          Production Review
                        </NavLink>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="nav-item">
                        <NavLink to="/daily-review" className="nav-link">
                          Production Review
                        </NavLink>
                      </li>
                    </>
                  )}
                  <li>
                    <span className="d-flex justify-content-center align-items-center gap-3">
                      <span className="user-info position-relative d-flex justify-content-center align-items-center">
                        <span className="m-0 text-white user-info__tooltip font-md">
                          {currentUserInfo.name}
                        </span>
                        <img
                          className="button__image"
                          src="./avatar.jpeg"
                          alt=""
                        />
                      </span>
                      <span className="m-0 text-white font-lg">
                        {currentUserInfo?.name}
                      </span>
                    </span>
                  </li>
                  <li className="nav-item">
                    <button
                      className="font-lg d-flex justify-content-center align-items-center bg-transparent border-0"
                      onClick={logout}
                    >
                      <p className="m-0 font-lg text-white">Logout</p>
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </nav>
      ) : (
        <></>
      )}
      <div
        className="container-fluid main-container position-relative"
        style={{ marginTop: "60px" }}
      >
        {currentUserInfo?.roles ? <Outlet /> : <Login />}
      </div>
    </main>
  );
};

export default Layout;
