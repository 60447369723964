export type USER_INFO = "USER_INFO";

export interface IUserInfo {
    accessToken: string,
    refreshToken: string,
    name: string,
    email: string,
    roles: string[],
}

export interface IUserPayload {
    type: USER_INFO,
    payload: IUserInfo | null
}

/**
 * Returns a payload object that wraps the "userInfo" objects
 * @param userInfo
 * @returns 
 */
export const getUserInfoPayload = (userInfo: IUserInfo | null): IUserPayload => {
    return {
        type: "USER_INFO",
        payload: userInfo 
    }
}