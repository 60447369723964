import * as React from "react";
import { useMediaQuery } from "react-responsive";
import { ReactGrid, CellChange, Row, Column} from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import "./style.css";
import ScheduleGridMobile from "./ScheduleGridMobile";
import ScheduleModal from "./ScheduleModal";
import { useLocation } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import WeekDays from "./weekDays";

const { REACT_APP_API_BASE_URL } = process.env;


type Id = number | string;
const reorderArray = <T extends {}>(arr: T[], idxs: number[], to: number) => {
  const movedElements = arr.filter((_, idx) => idxs.includes(idx));
  const targetIdx = Math.min(...idxs) < to ? to += 1 : to -= idxs.filter(idx => idx < to).length;
  const leftSide = arr.filter((_, idx) => idx < targetIdx && !idxs.includes(idx));
  const rightSide = arr.filter((_, idx) => idx >= targetIdx && !idxs.includes(idx));
  return [...leftSide, ...movedElements, ...rightSide];
}

interface ResponseField {
  RowId: number;
  Serial: string;
  JobContactLastName: string;
  Model: string;
  Dealer: string;
  Status: string;
  PreferredCompletion: string;
  JobID: string;
  Notes: string;
  ChassisNotes: string;
  ElectricalSystem: string;
  UpgradePack: string;
  ProductionValue: string;
  DrawnBy:string;
  DrawnDate: string;
  Description: string;
}
function ScheduleGrid() {
  const isBigScreen = useMediaQuery({ query: "(min-width: 992px)" });
  const [data, setData] = React.useState<ResponseField[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [modalShow, setModalShow] = React.useState(false);
  const [dataItemIndex, setDataItemIndex] = React.useState<number | undefined>();
  const [columns, setColumns] = React.useState<any>();
  const [rows, setRows] = React.useState<any>();
  const [selectedPDSData, setSelectedPDSData] = React.useState<any>();
  // const [noteCell, setNoteCell] = React.useState<string>("");
  const [selectedCell, setSelectedCell] = React.useState<any>();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10000);
  const [totalItems, setTotalItems] = React.useState(0);
  const [configData, setConfigData] = React.useState<any>();
  
  const location = useLocation();

  let searchQuery = {query: '', isRefresh: false};
  // let searchQuery:any;
  if(location.state)
    searchQuery  = location.state;

  React.useEffect(() => {
    const fetchConfigData = async() => {
      try{
        const configResponse = await fetch(`${REACT_APP_API_BASE_URL}/get_configuration_info`);
        const configResponseData = await configResponse.json();
        setPageSize(configResponseData["Items per page"]);
        setConfigData(configResponseData);
      }
      catch(error){
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    }
    fetchConfigData();
  }, [])
  
  React.useEffect(() => {
    
    const fetchData = async (srcQuery?: any) => {
      // srcQuery ? setCurrentPage(prevPage => 1 ) : setCurrentPage(prevPage => prevPage);
      setLoading(true);
      try {
        // const query = !srcQuery ? `page_num=${currentPage}&page_size=${configResponseData["Items per page"] ?? pageSize}` : `query=${srcQuery}`;
        const query = `query=${srcQuery}&page_num=${currentPage}&page_size=${pageSize}`;
        const response = await fetch(`${REACT_APP_API_BASE_URL}/xml_to_json_merged_two?${query}`);
        // const nddResponse = await fetch(`${REACT_APP_API_BASE_URL}/get_all_schedule_data`);
        // const nddResponseData = await nddResponse.json();
        const responseData = await response.json();
        // let headerAddedData: ResponseField[] = [
        //   {
        //     Serial: "",
        //     JobContactLastName: "",
        //     Model: "",
        //     Dealer: "",
        //     Status: "",
        //     PreferredCompletion: "",
        //     JobID: "",
        //     Description: ""
        //   },
        //   ...responseData.data,
        // ];
        
        // const headerAddedDataTwo: ResponseField[] = [
        //   {
        //     JobID: "",
        //     Notes: "",
        //     Status: "",
        //     ChassisNotes: "",
        //     ElectricalSystem: "",
        //     UpgradePack: "",
        //   },
        //   ...nddResponseData,
        // ];
        
        // let mergedData: ResponseField[] = []
        // if(headerAddedData && headerAddedDataTwo){
        //   mergedData = mergeData(headerAddedData, headerAddedDataTwo);
        // }
        
        // const filteredData = mergedData.filter(item => {
        //   const completionDate = new Date(item.PreferredCompletion.slice(0,10));
        //   const filterDate = new Date('2024-02-05');
        //   return completionDate >= filterDate;
        // }).map(item => {
        //     const completionDate = new Date(item.PreferredCompletion);
        //     const formattedDate = completionDate.toLocaleDateString('en-GB'); // Change 'en-GB' to your desired locale
        //     return { ...item, PreferredCompletion: formattedDate };
        // });
        const formattedRowId = (responseData.data).map((item:any, index:any) => {
          item.RowId= index;
          return item;
        });
       
        setData(formattedRowId);
        setRows(getRows(formattedRowId));
        setColumns(getColumns())
        setLoading(false);
        setTotalItems(responseData.total_items);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    if(searchQuery.query)
    {
      const delayFetch = setTimeout(() => {
        fetchData(searchQuery.query);
      }, 1000)
      return () => clearTimeout(delayFetch);
    }
    else{
      fetchData(searchQuery.query);
    }

  }, [pageSize, searchQuery, currentPage]);

  React.useEffect(() => {
    setCurrentPage(1)
  },[searchQuery])

  const mergeData = (headerData1: ResponseField[], headerData2: ResponseField[]) => {
    // Initialize an empty array to store the merged data
    const combinedData: ResponseField[] = [];
  
    headerData1.forEach((headerData1Item: ResponseField) => {
      const matchingItem = headerData2.find((item: ResponseField) => item.JobID === headerData1Item.JobID);
      if (matchingItem) {
        combinedData.push({ ...headerData1Item, ...matchingItem });
      }else{
        combinedData.push({ ...headerData1Item});
      }
    });
    return combinedData;
  };
  //Handle Field Text Changes
  const handleChanges = (changes: CellChange[]) => {
    const apiUrl = `${REACT_APP_API_BASE_URL}/save_schedule_data`;
    changes.forEach(change => {
      if (change.type === 'text' && (change.columnId === 'status' || change.columnId === 'notes' || change.columnId === "chassisNotes" || change.columnId === "electricalSystem" || change.columnId === "upgradePack")) {
        setData((prevData) => 
        prevData.map((item)=>{
          if(item.RowId == change.rowId){
            // const parts = item.PreferredCompletion.split('/'); // Split the date string by '/'
            // const preferredCompletionDate = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`); // Rearrange the parts into YYYY-MM-DD format
            const requestBody = {
              createdDate: Date.now(),
              jobId: item.JobID,
              chassisNo: item.Serial ?? "",
              customerName: item.JobContactLastName ?? "",
              model: item.Model ?? "",
                dealer: item.Dealer ?? "",
                status: item.Status ?? "",
                completionDate: item.PreferredCompletion ?? "",
                notes: item.Notes ?? "",
                chassisNotes: item.ChassisNotes ?? "",
                electricalSystem: item.ElectricalSystem ?? "",
                upgradePack: item.UpgradePack ?? "",
                productionValue: item.ProductionValue ?? "",
                drawnBy: item.DrawnBy ?? "",
                drawnDate: item.DrawnDate ?? null
              };
              
              console.log('cell change', change.columnId);
              if(change.columnId === 'status'){
                requestBody.status = change.newCell.text;
                rows.find((item:any) => item.rowId === change.rowId).cells[4].text = change.newCell.text;   
              } else if(change.columnId === 'notes'){
                requestBody.notes = change.newCell.text;
                rows.find((item:any) => item.rowId === change.rowId).cells[6].text = change.newCell.text; 
                console.log(rows.find((item:any) => item.rowId === change.rowId).cells[6].text); 
              }else if(change.columnId === 'chassisNotes'){
                requestBody.chassisNotes = change.newCell.text;
                rows.find((item:any) => item.rowId === change.rowId).cells[7].text = change.newCell.text; 
              }else if(change.columnId === 'electricalSystem'){
                requestBody.electricalSystem = change.newCell.text;
                rows.find((item:any) => item.rowId === change.rowId).cells[8].text = change.newCell.text; 
              }else if(change.columnId === 'upgradePack'){
                requestBody.upgradePack = change.newCell.text;
                rows.find((item:any) => item.rowId === change.rowId).cells[9].text = change.newCell.text; 
              }
              
              const requestOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify(requestBody)
              };

              fetch(apiUrl, requestOptions)
              .then((response) => response.json())
              .then(response => {
                // console.log(response);
              })
              .catch(error => {
                console.log("Update error", error);
              });
              
            }
            return item
          })
        );
      }
    })
  }
  //Handle Open Modal
  const handleOpenModal = (selected:any) => {
    if(selected[0].columns[0].columnId === "chassisNo"){
      setSelectedCell(selected[0].first.row.cells[selected[0].first.column.idx].text)
    }
    else {
      setSelectedCell('')
    }
    setSelectedPDSData(selected);
    if(selected[0].last.row.cells[8].text && selected[0].columns[0].columnId !== 'JobId'){
      setSelectedPDSData(selected[0].last.row.cells[8].text);
      setDataItemIndex(selected[0].rows[0].idx);
      
    }
  }
  // Handle Rows Dragging
  const handleRowsReorder = (targetRowId: Id, rowIds: Id[]) => {
    setData((prevData) =>{
      const to = prevData.findIndex((item) => item.RowId === targetRowId);
      const rowIndices = rowIds.map((id:Id) => prevData.findIndex((item) => item.RowId === id));
      return reorderArray(prevData, rowIndices, to);
    });
  }
  const handleCanReorderRows = (targetRowId:Id, rowIds:Id[]): boolean => {
    return targetRowId !== 'Row ID';
  }

  //Add New Row
  const getNewRowId = () => {
    // Check if the array is not empty
    if (data.length > 0) {
      const lastRowID = data[data.length - 1].RowId;
      const newRowId = lastRowID + 999999;
      return newRowId;
    }
    else{
      return 0;
    }
  }
  const isNewRow = (row:ResponseField) => {
    // Check if the row has the RowID of a new row
    // return row.RowId === getNewRowId();
  };

  const handleAddRow = () => {
    const newRow: ResponseField = {
      RowId: getNewRowId(),
      JobID: '',
      // ProductionStartDate: '',
      // WeekNumber: '',
      JobContactLastName: '',
      Model: '',
      Serial: '',
      Dealer: '',
      Status: '',
      PreferredCompletion: '',
      Notes: '',
      ChassisNotes: '',
      ElectricalSystem: '',
      UpgradePack: '',
      ProductionValue: '',
      DrawnBy: "",
      DrawnDate: "",
      Description: ""
    };

    // Update the state to include the new row
    setData((prevData:any) => [prevData[0], newRow, ...prevData.slice(1)]);
  };


  const handleColumnResize = (ci: Id, width: number) => {
    setColumns((prevColumns:any) => {
      const columnIndex = prevColumns.findIndex((el:any) => el.columnId === ci);
      if (columnIndex !== -1){
        const resizedColumn = prevColumns[columnIndex];
        const updatedColumn = { ...resizedColumn, width };
        prevColumns[columnIndex] = updatedColumn;
        return [...prevColumns];
      }
      return prevColumns;
    });
  }

  const handleRowSelect = (selected:any) => {
    // console.log("selected",selected);
  }

  const handlePageClick = (pageData: any) => {
    let cPage = pageData.selected + 1;
    setCurrentPage(cPage);

  }
  const headerRow: Row = {
    rowId: "header",
    height: 150,
    cells: [
      { type: "header", text: "Chassis No" },
      { type: "header", text: "Cusomter Name" },
      { type: "header", text: "Model" },
      { type: "header", text: "Dealer" },
      { type: "header", text: "Status" },
      { type: "header", text: "Completion Date" },
      { type: "header", text: "Notes" },
      { type: "header", text: "Chassis Notes" },
      { type: "header", text: "Electrical System" },
      { type: "header", text: "Upgrade Pack" },
      // { type: "header", text: "Drawn By" },
      // { type: "header", text: "Drawn Date" },
    ]
  };
  const getColumns = (): Column[] => [
    { columnId: "chassisNo", width: 80 },
    { columnId: "customerName", width: 150 },
    { columnId: "model", width: 370 },
    { columnId: "dealer", width: 280 },
    { columnId: "status", width: 200 },
    { columnId: "completionDate", width: 120 },
    { columnId: "notes", width: 60 },
    { columnId: "chassisNotes", width: 100 },
    { columnId: "electricalSystem", width: 100 },
    { columnId: "upgradePack", width: 60 },
  ];

  const getRows = (field: ResponseField[]): Row[] => [
    headerRow,
    ...field.map<Row>((res, idx) => ({
        rowId: res.RowId,
        cells: [
            { type: "text", text: res.Serial },
            { type: "text", text: res.JobContactLastName },
            { type: "text", text: res.Model },
            { type: "text", text: res.Dealer },
            { type: "text", text: res.Status },
            { type: "text", text: res.PreferredCompletion },
            { type: "text", text: res.Notes ?? ""},
            { type: "text", text: res.ChassisNotes ?? chesisNotesFieldAutoInput(res.Description)  },
            { type: "text", text: res.ElectricalSystem ?? electricalSystemFieldAutoInput(res.Description) },
            { type: "text", text: res.UpgradePack ??  upgradePackFieldAutoInput(res.Description) },
        ]
    }))
  ];
  const electricalSystemFieldAutoInput = (field: string) => {
    field = field.toString().toLowerCase();
    if (field.includes("enerdrive") && field.includes(" i ") && field.includes("400")) {
      return "EN I 400";
    } else if (field.includes("victron")) {
      return "Victron";
    } else if (field.includes("redarc redvision") && field.includes("ilo")) {
      return "Redvision";
    }else if (field.includes("enerdrive 600") && field.includes(" i ")) {
      return "EN I 600";
    } else {
      return "";
    }
  };

  const upgradePackFieldAutoInput = (field: string) => {
    field = field.toString().toLowerCase();
    if (field.includes("excalibur") && field.includes("upgrade pack")) {
      return "EXUP";
    } else if (field.includes("all terrain upgrade")) {
      return "AT";
    } else if (field.includes("x country upgrade")) {
      return "XC";
    } else {
      return "";
    }
  };

  const chesisNotesFieldAutoInput = (field: string) => {
    field = field.toString().toLowerCase();
    if (field.includes("extreme off road") && field.includes("upgrade pack")) {
      return "Extreme off road";
    } else if (field.includes("xcountry")) {
      return "x countrky";
    }else if (field.includes("hot dipped galvanised")) {
      return "Hot dip Gal";
    }else if (field.includes("upgrade to stage 2 tandem axle airbags")) {
      return "Stage 2 Airbags";
    } else {
      return "";
    }
  };

  return (
    <div>
      <div className="myGrid">
        <div className={`${loading ? 'd-flex align-items-center justify-content-between' : 'd-none'}`}>
            <h1>Crusader Schedule Data</h1>
        </div>
        {loading ? (
          <div></div>
        ) : (
          <>
            {isBigScreen ? (
              <>
              <div className='d-flex align-items-center justify-content-between'>
                <h1>Crusader Schedule Data</h1>
                <button className="btn blue-btn d-flex align-items-center justify-content-between gap-2" onClick={handleAddRow}>
                  <svg className="plus-icon" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 128 128">
                    <path d="M 64 6.0507812 C 49.15 6.0507812 34.3 11.7 23 23 C 0.4 45.6 0.4 82.4 23 105 C 34.3 116.3 49.2 122 64 122 C 78.8 122 93.7 116.3 105 105 C 127.6 82.4 127.6 45.6 105 23 C 93.7 11.7 78.85 6.0507812 64 6.0507812 z M 64 12 C 77.3 12 90.600781 17.099219 100.80078 27.199219 C 121.00078 47.499219 121.00078 80.500781 100.80078 100.80078 C 80.500781 121.10078 47.500781 121.10078 27.300781 100.80078 C 7.0007813 80.500781 6.9992188 47.499219 27.199219 27.199219 C 37.399219 17.099219 50.7 12 64 12 z M 64 42 C 62.3 42 61 43.3 61 45 L 61 61 L 45 61 C 43.3 61 42 62.3 42 64 C 42 65.7 43.3 67 45 67 L 61 67 L 61 83 C 61 84.7 62.3 86 64 86 C 65.7 86 67 84.7 67 83 L 67 67 L 83 67 C 84.7 67 86 65.7 86 64 C 86 62.3 84.7 61 83 61 L 67 61 L 67 45 C 67 43.3 65.7 42 64 42 z"></path>
                  </svg>
                  <span>Add New</span>
                </button>
              </div>
              <div className="d-flex gap-5">
                  <div className="">
                    {
                      <WeekDays configData={configData} onRowSelect={handleRowSelect} selectedCell={selectedCell}/>
                    }
                  </div>
                  <div className="">
                    {
                      data?.length > 0 ? 
                      <>
                        <div className="schedule__data-grid">
                          <div>
                            <ReactGrid stickyTopRows={1}
                              rows={rows}
                              columns={columns}
                              onCellsChanged={handleChanges}
                              onSelectionChanged={handleOpenModal}
                              onRowsReordered={handleRowsReorder}
                              canReorderRows={handleCanReorderRows}
                              onColumnResized={handleColumnResize}
                              enableColumnSelection
                            />
                          </div>
                        </div>
                        <ReactPaginate
                          previousLabel={"<<"}
                          nextLabel={">>"}
                          breakLabel={"..."}
                          pageCount={Math.ceil(totalItems/pageSize)}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination justify-content-center pt-4"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                          forcePage={currentPage - 1}
                        />
                      </>
                      :
                      <div className="d-flex justify-content-center">
                        <p>No Data Found...</p>
                      </div>
                    }
                  </div>
              </div>
              {modalShow && dataItemIndex !== undefined && (
                <ScheduleModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                data={data}
                index={dataItemIndex}
                />
              )}
              </>
              
            ) : (
              <ScheduleGridMobile data={data}/>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default ScheduleGrid;
