import { IReOrderModel, IScheduleGridModel, IUpdateScheduleSubStatusModel } from "../../../generated/generated-proxies";
import server, { REACT_APP_API_BASE_URL } from "../../../generated/proxies";
import { ResponseField } from "./ScheduledFeed-models";

export default class ScheduledFeedServiceProvider {

    async fetchLineData(line_view: string,query: string, page_num: number, page_size: number): Promise<ResponseField[]>{
        const response = await server.productionScheduleData.productionScheduleDataProductionScheduleDataGet(
            {line_view, query, page_num, page_size},
            {baseURL: REACT_APP_API_BASE_URL}
        )
        return response.data.data;
    }

    async triggerDataUpdate() {
        await server.updateDataFromCots.updateDataFromCotsApiUpdateDataFromCotsGet({ baseURL: REACT_APP_API_BASE_URL });
    }

    async rearrangeData(selectedOption: string): Promise<ResponseField[]> {
        const response = await server.rearrangeScheduleData.rearrangeScheduleDataRearrangeScheduleDataLineGet(selectedOption, { baseURL: REACT_APP_API_BASE_URL })
        return response.data;
    }

    async updateReorderScheduleData(selectedOption: string, data: object[]) {
        const response = await server.updateScheduleData.updateScheduleDataUpdateScheduleDataLinePost(selectedOption, data, { baseURL: REACT_APP_API_BASE_URL })
        return response.data;
    }

    async updateModalCheckBoxData(checkBoxIdRequestBody: IUpdateScheduleSubStatusModel) {
        await server.updateScheduleSubstatus.updateScheduleSubStatusUpdateScheduleSubstatusPut(
            checkBoxIdRequestBody, { baseURL: REACT_APP_API_BASE_URL }
        )
    }

    async saveScheduleRowData(selectedOption: string, rowData: IScheduleGridModel) {
        const response = await server.saveScheduleData.saveScheduleDataModelSaveScheduleDataLinePost(selectedOption, rowData,
            { baseURL: REACT_APP_API_BASE_URL }
        )
        return response.data.data;
    }

    async modalSwapOptionsUpdate(textValues: any, rowId: number, selectedLine: string, fromChassisNo: string ){
        const response =  await server.swapOptionsUpdate.receiveSwapOptionsSwapOptionsUpdatePost(
            {
                textValues,
                rowId,
                selectedLine,
                fromChassisNo
            },
            { baseURL: REACT_APP_API_BASE_URL }
        )
        return response.data;
    }

    async removeReservedRow(lineName: string, rowId: number): Promise<ResponseField[]>{
        const response = await server.removeReservedItem.removeReservedItemRemoveReservedItemLineNameRowIdGet(lineName, rowId, { baseURL: REACT_APP_API_BASE_URL })
        return response.data;
    }

    async sendReorderDatatoDb(data: IReOrderModel){
        const response = await server.saveReorderDataToDb.saveReorderDataToDbSaveReorderDataToDbPost(data, { baseURL: REACT_APP_API_BASE_URL})
        return response.data;
    }
}
