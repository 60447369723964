import * as React from 'react';
import './form-style.css';
import { toast } from 'react-toastify';



const { REACT_APP_API_BASE_URL } = process.env;

function ResponsiveForm() {

    const [formData, setFormData] = React.useState<any>({});
    const [crvData, setCrvData] = React.useState<any>({});

    React.useEffect(() => {
        
        fetchMainLineData();
        fetchCRVLineData();
    }, []);

    const fetchMainLineData = async () => {
        try {
            const configResponse = await fetch(`${REACT_APP_API_BASE_URL}/get_main_line_configuration_data`);
            const responseData = await configResponse.json();
            setFormData(responseData);
        } catch (error) {
            console.error('Error fetching configuration data:', error);
        }
    };
    const fetchCRVLineData = async () => {
        try {
            const configResponse = await fetch(`${REACT_APP_API_BASE_URL}/get_crv_line_configuration_data`);
            const responseData = await configResponse.json();
            setCrvData(responseData);
        } catch (error) {
            console.error('Error fetching configuration data:', error);
        }
    };
    // Object.keys(formData).forEach(function (key) {
    //     console.log(key, formData[key]);
    // })

    const submitData = (event:any) => {
        event.preventDefault();
        const apiUrl1 = `${REACT_APP_API_BASE_URL}/update_main_line_configuration_data`;
        const apiUrl2 = `${REACT_APP_API_BASE_URL}/update_crv_line_configuration_data`;

        const requestOptions1 = {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(formData)
        };
        const requestOptions2 = {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(crvData)
        };

        fetch(apiUrl1, requestOptions1)
            .then((response) => response.json())
            .then(response => {
                setFormData(response)
            })
            .catch(error => {
                console.log("Update error", error);
            })
        
        fetch(apiUrl2, requestOptions2)
            .then((response) => response.json())
            .then(response => {
                setCrvData(response)
                toast.success("Saved successfully!");
            })
            .catch(error => {
                console.log("Update error", error);
            })
    }

    return (
        <div className='container'>
            <div className='d-flex justify-content-center'>
                    <div className="form_wrapper">
                        <form onSubmit={submitData} >
                            <div className="row">
                                <div className="col-6">
                                    <div className="title_container text-center pb-3">
                                        <h2>Main Line</h2>
                                    </div>
                                    <div className="row">
                                        {Object.entries(formData).map(([key, value]) => (
                                            <div className="col-12 input_field" key={key}>
                                                <label htmlFor="" className='form-label fw-medium font-lg'>{key}</label>
                                                {/* <input className='form-control' type="text" name="CalenderLimit" value={`${value}`} onChange={(e) => setFormData({ ...formData, [key]: e.target.value })} /> */}
                                                {key === "Start Date" ? (
                                                    <input className='form-control' type="date" name="CalenderLimit" value={`${value}`} onChange={(e) => setFormData({ ...formData, [key]: e.target.value })} />
                                                )
                                                : key === 'Production target per week' ? (
                                                    // <input className='form-control' type="text" name="CalenderLimit" readOnly value={`${value}`} onChange={(e) => setFormData({ ...formData, [key]: e.target.value })} />
                                                    <h6 className='fw-bold'>{`${value}`}</h6>
                                                )
                                                 : (
                                                    <input className='form-control' type="text" name="CalenderLimit" value={`${value}`} onChange={(e) => setFormData({ ...formData, [key]: e.target.value })} />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="title_container text-center pb-3">
                                        <h2>CRV Line</h2>
                                    </div>
                                    <div className="row">

                                        {Object.entries(crvData).map(([key, value]) => (
                                            <div className="col-12 input_field" key={key}>
                                                <label htmlFor="" className='form-label fw-medium font-lg'>{key}</label>
                                                {/* <input className='form-control' type="text" name="CalenderLimit" value={`${value}`} onChange={(e) => setFormData({ ...formData, [key]: e.target.value })} /> */}
                                                {key === "Start Date" ? (
                                                    <input className='form-control' type="date" name="CalenderLimit" value={`${value}`} onChange={(e) => setCrvData({ ...crvData, [key]: e.target.value })} />
                                                )
                                                : key === 'Production target per week' ? (
                                                    // <input className='form-control' type="text" name="CalenderLimit" readOnly value={`${value}`} onChange={(e) => setCrvData({ ...crvData, [key]: e.target.value })} />
                                                    <h6 className='fw-bold'>{`${value}`}</h6>
                                                )
                                                 : (
                                                    <input className='form-control' type="text" name="CalenderLimit" value={`${value}`} onChange={(e) => setCrvData({ ...crvData, [key]: e.target.value })} />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-6 mx-auto">
                                    <input className="btn blue-btn" type="submit" value="Save" />
                                </div>
                            </div>
                        </form>
                    </div>
            </div>
        </div>
        
    )
}
export default ResponsiveForm;