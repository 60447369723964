export class ScheduleModalPresenter{
    statusDropDownValues = [
        { value: "Draft", label: "Draft" },
        { value: "Cancelled", label: "Cancelled" },
        { value: "Submitted", label: "Submitted" },
        { value: "Confirmed Pending", label: "Confirmed Pending" },
        { value: "Rejected", label: "Rejected" },
        { value: "Accepted", label: "Accepted" },
        { value: "Final", label: "Final" },
        { value: "AwaitingDealerSignOff", label: "AwaitingDealerSignOff" },
        { value: "DealerSignedOff", label: "DealerSignedOff" },
        { value: "Planned", label: "Planned" },
        { value: "Customer", label: "Customer" },
        { value: "Scheduled", label: "Scheduled" },
        { value: "Production", label: "Production" },
        { value: "Completed", label: "Completed" },
        { value: "Delivered", label: "Delivered" },
        { value: "Retailed", label: "Retailed" },
      ];
    statusCheckboxOptions = [
        { id: "1", label: "Chassis Ordered" },
        { id: "2", label: "Body Panels Ordered" },
        { id: "3", label: "Benchtop Ordered" },
        { id: "4", label: "Splashback Ordered" },
        { id: "5", label: "Upholstery Ordered" },
      ];
    ReservationDealerOptions = [
      {value: 'ALM Group', label: 'ALM Group' },
      {value: 'Canberra RV World', label: 'Canberra RV World' },
      {value: 'Destiny RV', label: 'Destiny RV' },
      {value: 'Crusader Bendigo', label: 'Crusader Bendigo' },
      {value: 'Carlon Crusader', label: 'Carlon Crusader' },
      {value: 'Lewis RV', label: 'Lewis RV' },
      {value: 'Highway Caravan Centre', label: 'Highway Caravan Centre' },
      {value: 'Caravana RV', label: 'Caravana RV' },
      {value: 'Crusader Newcastle', label: 'Crusader Newcastle' },
      {value: 'Crusader Melbourne', label: 'Crusader Melbourne' },
      {value: 'Motoco RV', label: 'Motoco RV' },
      {value: 'Albury Wodonga RV World', label: 'Albury Wodonga RV World' },
      {value: 'Everything RV', label: 'Everything RV' },
      {value: 'Hervey Bay Caravans', label: 'Hervey Bay Caravans' },
      {value: 'Cameron Caravans', label: 'Cameron Caravans' },
      {value: 'The Caravan Hub', label: 'The Caravan Hub' },
    ]
}