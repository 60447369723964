import { IBodyUploadFileUploadFilePost, IJobListModel, IMattressJobListModel } from "../../../generated/generated-proxies";
import PickListServiceProvider from "./PickList-services";
import { IJobList } from "./pickList-models";

export class PickListPresenter {
  productionData: IJobList[] = [
    { name: "Mattresses" },
    { name: "Electrical Prep" },
    { name: "Toilet, Upholstry, and Table Tops" },
    { name: "Fridge" },
    { name: "Oven" },
  ];
  private serviceProvider = new PickListServiceProvider();

  modifyJobList(jobList: string[]) {
    const newJobList = `Job List\n${jobList.join("\n")}`;
    this.productionData = this.productionData.map((item) => {
      if (item.name === "Mattresses") {
        return { ...item, jobList: newJobList };
      }
      return item;
    });
  }

  async uploadFile(file: IBodyUploadFileUploadFilePost) {
    return await this.serviceProvider.uploadFileToDb(file);
  }
  async getFileName() {
    return await this.serviceProvider.getFileNameFromDb();
  }
  async getProductionTargetSeqFromFile() {
    return await this.serviceProvider.getProductionTargetSeqFromFile();
  }
  async getChassisDeliveredData() {
    return await this.serviceProvider.getChassisDeliveredData();
  }
  async getMattressJobList() {
    const response = await this.serviceProvider.getMattressJobList();
    this.modifyJobList(response);
    return this.productionData;
  }
  async updateMattressJobList(inputValues: IMattressJobListModel) {
    const response = await this.serviceProvider.updateMattressJobList(
      inputValues
    );
    this.modifyJobList(response);
    return this.productionData;
  }
  async updateItemsJobList(inputValues: IJobListModel) {
    const response = await this.serviceProvider.updateItemssJobList(
      inputValues
    );
    this.modifyJobList(response);
    return this.productionData;
  }
}