import userStorage from "../common/userStorage";
import { IUserPayload } from "./actions";

const initialState = userStorage.getAuthInfo() ?? null;
const userReducer = (state = initialState, action: IUserPayload) => {
    switch (action.type) {
        case "USER_INFO":
            return action.payload ? { ...action.payload } : null;
        default:
            return state;
    }
}
export default userReducer;