import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox } from '@mui/material';
import React from 'react';
import CycleTimer from './CycleTImer';

function ProductionLineData(props:any) {
    const { prodLineData, lineName } = props;
    const getBackgroundColor = (stationAlert: any) => {
      const colorMap: Record<string, string> = {
        "Alert": "#FF0000",
        "Work Active": "#FFA500",
        "Work Complete": "#008000",
        "Stores": "#0000FF",
        "Maintenance": "#808080",
      };
      return colorMap[stationAlert] || "inherit";
    };
    return (
        <TableContainer component={Paper} elevation={2} style={{ marginTop: '10px'}}>
            <Table size="small">
                <TableHead>
                    <TableRow style={{ backgroundColor: "rgb(255 253 249)" }}>
                        <TableCell colSpan={prodLineData.length + 1} className="fw-bold" style={{ width: "300px", fontSize: '1.6vmin' }}>
                            <span style={{ display: 'inline-flex', alignItems: 'center', justifyContent: "between", gap: '8px' }}>
                                {lineName} ASSEMBLY
                                {/* <CycleTimer/> */}
                                {lineName === "MAIN LINE" &&
                                    <CycleTimer line="main"/>
                                }
                                {lineName === "CRV LINE" &&
                                    <CycleTimer line="crv"/>
                                }
                            </span>
                        </TableCell>
                    </TableRow>
                    
                </TableHead>
                <TableBody>
                    <TableRow style={{ backgroundColor: "#f0f8ff" }}>
                        <TableCell style={{ fontSize: '1.3vmin' }}>Station #</TableCell>
                        {prodLineData.map((station:any, index:any) => (
                            <TableCell key={index} style={{ fontSize: '1.3vmin', textAlign: 'center' }}>{station.lineNumber}</TableCell>
                        ))}
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ fontSize: '1.3vmin' }}>Chassis No</TableCell>
                        {prodLineData.map((station:any, index:any) => (
                            <TableCell key={index} style={{ fontSize: '1.3vmin', textAlign: 'center' }}>{station.chassisNo}</TableCell>
                        ))}
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ fontSize: '1.3vmin' }}>Status</TableCell>
                        {prodLineData.map((station:any, index:any) => (
                            <TableCell key={index} style={{ textAlign: 'center',
                                backgroundColor: getBackgroundColor(station.stationAlert)
                             }}>
                                
                            </TableCell>
                        ))}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};
export default ProductionLineData;