import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { IProductionTargetSequenceProps } from "../pickList-models";

function ProductionTargetSequence(props: IProductionTargetSequenceProps) {
  return (
    <div>
      <h2>Production Target</h2>
      <TableContainer component={Paper}>
        <Table size="small" className="p-0">
          <TableHead>
            <TableRow style={{ backgroundColor: "rgb(59, 17, 80)" }}>
              <TableCell style={{ width: "10px" }}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "white",
                    fontFamily: "League Spartan, sans-serif",
                  }}
                >
                  Sl No
                </Typography>
              </TableCell>
              <TableCell style={{ width: "10px" }}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "white",
                    fontFamily: "League Spartan, sans-serif",
                  }}
                >
                  Chassis No
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.seqData.map((item, i) => (
              <TableRow key={i}>
                <TableCell>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: "black",
                      fontFamily: "League Spartan, sans-serif",
                    }}
                  >
                    {i + 1}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: "black",
                      fontFamily: "League Spartan, sans-serif",
                    }}
                  >
                    {item}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
export default ProductionTargetSequence;
