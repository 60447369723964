import { useEffect, useState } from "react";
import { PickListPresenter } from "../PickList-presenter";
import {
  IJobListModel,
  IMattressJobListModel,
} from "../../../../generated/generated-proxies";
import { toast } from "react-toastify";
import { IJobList } from "../pickList-models";
import JobItemDetailsModal from "./JobItemDetailsModal";

function ProductionSequenceMaintain() {
  const pickListDataPresenter = new PickListPresenter();
  const [allItemJobList, setAllItemJobList] = useState(
    pickListDataPresenter.productionData
  );
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState<IJobList>({
    name: "",
  });

  async function getMattressJobList() {
    const list = await pickListDataPresenter.getMattressJobList();
    setAllItemJobList(list);
  }

  useEffect(() => {
    getMattressJobList();
  }, []);

  // const updateJobList = async (event: any) => {
  //   event.preventDefault();
  //   const form = event.target;
  //   console.log("form", form);
  //   const mattressJobInfo: IMattressJobListModel = {
  //     StartNo: form.StartNo.value,
  //     Quantity: form.Quantity.value,
  //   };
  //   if (Object.values(mattressJobInfo).every((value) => Boolean(value))) {
  //     const itemJobList = await pickListDataPresenter.updateMattressJobList(
  //       mattressJobInfo
  //     );
  //     setAllItemJobList(itemJobList);
  //     form.reset();
  //   } else {
  //     toast.error("Please enter a Value");
  //   }
  // };

  const updateJobList = async (event: any) => {
    event.preventDefault();
    const form = event.target;
    console.log("form", form);
    const mattressJobInfo: IJobListModel = {
      StartNo: form.StartNo.value,
      Quantity: form.Quantity.value,
      SectionName: "Electrical Prep",
    };
    console.log("mattressJobInfo", mattressJobInfo);
    if (Object.values(mattressJobInfo).every((value) => Boolean(value))) {
      const itemJobList = await pickListDataPresenter.updateItemsJobList(
        mattressJobInfo
      );
      console.log("list", itemJobList);
      setAllItemJobList(itemJobList);
      form.reset();
    } else {
      toast.error("Please enter a Value");
    }
  };

  const showModal = (item: IJobList) => {
    setModalShow(true);
    setModalData(item);
  };

  return (
    <>
      <div style={{ width: "530px" }} className="">
        <h2>Item Job List</h2>
        <div className="">
          {allItemJobList.map((item, i) => (
            <div className="bg-light-gray row mb-1 py-3 px-2 rounded-2" key={i}>
              <div className="col-4">
                <div className="d-flex flex-column gap-2 h-100">
                  <p className="fw-semibold">{item.name}: </p>
                  <button
                    className="btn btn-secondary"
                    onClick={() => showModal(item)}
                  >
                    Details
                  </button>
                </div>
              </div>
              <div className="col-8">
                <form onSubmit={updateJobList}>
                  <div className="d-flex gap-3">
                    <div className="d-flex gap-2">
                      <p className="mb-0">Start No.</p>
                      <input
                        className="prod__input form-control"
                        name="StartNo"
                        style={{ width: "60px", height: "30px" }}
                        type="text"
                      />
                    </div>
                    <div className="d-flex gap-2">
                      <p className="mb-0">Qty.</p>
                      <input
                        className="prod__input form-control"
                        name="Quantity"
                        style={{ width: "60px", height: "30px" }}
                        type="text"
                      />
                    </div>
                    <button
                      // onClick={updateJobList}
                      className="btn blue-btn"
                      style={{ height: "fit-content" }}
                    >
                      Submit
                    </button>
                  </div>
                </form>
                <div className="mt-2">
                  <textarea
                    className="prod__input form-control"
                    name="item-details"
                    value={item.jobList}
                    rows={item.jobList?.split("\n").length}
                    cols={34}
                    wrap="soft"
                    placeholder="Empty Job List"
                    disabled
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <JobItemDetailsModal
        show={modalShow}
        jobListItem={modalData}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}
export default ProductionSequenceMaintain;
